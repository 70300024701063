<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="calories"
    class="ma-2 elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>СЛОИ</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Новый</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.name"
                      label="Название"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
              <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Обновить</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LayersTab",

  data: () => ({
    inputName: "",
    dialog: false,
    headers: [
      {
        text: "Название",
        align: "start",
        value: "name"
      },
      { text: "Действия", value: "actions", sortable: false, width: 100 }
    ],
    editedIndex: -1,
    editedItem: {
      name: ""
    },
    defaultItem: {
      name: ""
    }
  }),

  computed: {
    ...mapGetters(["allLayersData"]),
    formTitle() {
      return this.editedIndex === -1 ? "Новый" : "Редактировать";
    },
    desserts() {
      return this.allLayersData;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions([
      "getLayersData",
      "destroyLayerData",
      "createLayerData",
      "pushLayerData"
    ]),
    initialize() {
      this.getBreedsData();
    },
    save() {
      if (this.editedIndex > -1) {
        this.pushLayerData({
          id: this.editedItem.id,
          name: this.editedItem.name
        });
      } else {
        this.createLayerData(this.editedItem.name);
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
  }
};
</script>

<style scoped></style>
